/* Resets and general styles */
body, h1, p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Full container for the app, ensuring it covers the full height of the viewport */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #111816; /* Dark background color */
}

/* Header to contain logo containers */
.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 280px; /* Space between logo containers */
}

/* Container for each logo and icon with independent links */
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Ensures text aligns well under the logos */
}

/* Consistent size for all logos */
.logo {
  height: 250px; /* Maintain aspect ratio */
}

/* Consistent size for all icons */
.icon {
  height: 30px; /* Adjust size as needed */
  margin-top: 40px; /* Space between logo and icon */
}

.logo:hover {
  transform: scale(1.1); /* Scales up to 110% of original size */
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out; /* Smooth transition for transform and filter */
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.2)); /* Very light white glow around the shape of the SVG */
}

/* Glowing effect on hover for icons using drop-shadow */
.icon:hover {
  filter: drop-shadow(0 0 7px rgba(255, 255, 255, 0.2)); /* Reduced blur radius and lower opacity for a subtler glow */
  transition: filter 0.3s ease-in-out; /* Smooth transition effect */
}

/* Footer styling for the "Powered by SATI" text */
.App-footer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: #888;
}

.App-footer a {
  color: #888;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}

/* Media Queries for Mobile and Tablet */
@media (max-width: 768px) { /* Adjustments for tablets */
  .App-header {
    flex-direction: column; /* Stack logo containers vertically */
    gap: 50px; /* Reduce space between containers */
  }

  .logo {
    height: 150px; /* Reduce size of logos for smaller screens */
  }

  .icon {
    height: 20px; /* Reduce size of icons */
    margin-top: 20px; /* Reduce space above icons */
  }
}

@media (max-width: 480px) { /* Adjustments for mobile phones */
  .App-header {
    gap: 80px; /* Reduce space even more for smaller containers */
  }

  .logo {
    height: 150px; /* Further reduce logo size for very small screens */
  }

  .icon {
    height: 24px; /* Further reduce icon size */
    margin-top: 20px; /* Reduce space above icons even more */
  }
}
